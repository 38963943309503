export const goToCounselingForm = ({ urlParams }: { urlParams: string }) => {
  let path = "";
  if (process.env.GATSBY_ACTIVE_ENV == "production") {
    path = `https://cs.plusmedical.jp/counseling_form/step1${urlParams}`;
  } else {
    path = `https://stg-cs.plusmedical.jp/counseling_form/step1${urlParams}`;
  }

  window.open(path, "_blank");
};
